<template>
	<v-card>
		<v-card-text>
			<v-row no-gutters class="pt-4">
				<v-spacer></v-spacer>
				<v-col>
					<div class="headline">HELP</div>
				</v-col>
				<v-col cols="auto">
					<v-btn icon @click="$emit('close')">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<template v-for="(content,i) in contents">
				<div
					:key="i"
					class="pt-2"
				>
					<div class="font-weight-bold">{{ content.title }}</div>
					<div>{{ content.text }}</div>
				</div>
			</template>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props:{
		contents:{
			type: Array,
			default: ()=>{ return [] }
		}
	},
	data(){
		return {
		}
	},
	created(){
	},
	methods:{
	}
}
</script>
