<template>
	<v-container>
		<v-row no-gutters>
			<v-col class="headline mb-1">Vehicle Information</v-col>
			<v-col cols="auto">
				<v-btn
					depressed
					x-small fab
					color="secondary"
					@click="help_dialog = true"
				>
					<v-icon color="primary">mdi-help</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col 
				md="12"
				style="height: calc(100vh - 220px);"
			>
				<v-container
					style="background-color: black;"
					class="rounded-lg vehicle-information"
				>
					<v-row
						no-gutters
						class="overflow-auto pa-7"
						style="color:white;"
					>
						<v-col cols="12" md="5">
							<v-row dense class="body-2">
								<v-col cols="12" class="pt-5">
									<span>Model Code</span>
									<v-autocomplete
										v-model="form.theModel"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Search Model Code"
										class="radius-10 pr-0"
										:items="option_model_code"
										:loading="model_loading_"
										:search-input.sync="search_input_"
										item-text="dk_code" item-value="id"
										return-object
										clearable
										@input="selectModel()"
										@click:clear="clearSelection()"
									>
										<template #append>
											<v-icon>mdi-magnify</v-icon>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" class="pt-5">
									<span>Maker</span>
									<span class="error--text">*</span>
									<v-autocomplete
										v-model="form.make"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Search Maker"
										class="radius-10 pr-0"
										:items="option_make"
										item-text="name" item-value="id"
										@input="getSDModelOption()"
									></v-autocomplete>
								</v-col>
								<v-col cols="12" class="pt-5">
									<span>Model</span>
									<span class="error--text">*</span>
									<v-autocomplete
										v-model="form.model_name"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Model Name"
										class="radius-10 pr-0"
										:items="option_model"
										item-text="model_name" item-value="model_name"
										@input="getSDYearOption()"
									></v-autocomplete>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="5" class="ml-md-10">
							<v-row dense class="body-2">
								<v-col cols="12" class="pt-5">
									<span>Year</span>
									<span class="error--text">*</span>
									<v-autocomplete
										v-model="form.year"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Year"
										class="radius-10 pr-0"
										:items="option_year"
										item-text="year" item-value="year"
										@input="getModelCodeOption(true);"
									></v-autocomplete>
								</v-col>
								<v-col cols="12" class="pt-5">
									<span>Region</span>
									<v-autocomplete
										v-model="form.region"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Region"
										class="radius-10 pr-0"
										:items="option_region"
										item-text="name" item-value="id"
										@input="getSeaterNumberOption()"
									></v-autocomplete>
									<!-- {{ option_region }} -->
								</v-col>
								<!-- {{ form.region }}
								{{ form.seater_number }} -->
								<v-col cols="12" class="pt-5">
									<span>Seater Number</span>
									<v-autocomplete
										v-model="form.seater_number"
										filled rounded
										style="background-color: white;"
										dense hide-details
										color="secondary"
										placeholder="Seater Number"
										class="radius-10 pr-0"
										:items="option_seater_number"
										item-text="name" item-value="id"
										@input="getModelCodeOption()"
									></v-autocomplete>
									<!-- {{ option_seater_number }} -->
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
				<v-container
					style="background-color: #6087C2; height: 6em; color: white;"
					class="rounded-lg mt-10 mb-10"
					fill-height fluid
				>
					<v-row
						align="center"
						justify="center"
					>
						<v-col>
							<h3>{{ ((form.make ? getMakerName(form.make) : '') + ' ' + (form.model_name ? form.model_name : '') + ' ' + (form.year ? form.year : '') + (form.seater_number ? ' - ' + getSeaterName(form.seater_number) : '')) }}</h3>
						</v-col>
					</v-row>
				</v-container>
				<v-container
					class="mt-10"
				>
					<v-row justify="end">
						<v-col cols="12" md="3" sm="6">
							<v-btn
								block
								depressed
								:disabled="buttonOff"
								color="primary"
								class="text-capitalize secondary--text radius-10"
								@click="startCustomization()"
							>
								Start to Customization
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>

		<v-dialog v-model="help_dialog" max-width="500px" persistent>
			<InfoCard 
				:contents="[
					{ title: 'Model Code', text: 'Car set model number'},
					{ title: 'Maker', text: 'Car\'s brand'},
					{ title: 'Model', text: 'Car\'s model. Available after choosing Maker.'},
					{ title: 'Year', text: 'Car\'s manufacture year. Available after choosing Maker.'},
					{ title: 'Region', text: 'Car\'s region. Available after choosing Maker.'},
					{ title: 'Seater Number', text: 'Number of seat in the car model. Available after choosing Maker.'},
				]"
				@close="help_dialog = false"
			></InfoCard>
		</v-dialog>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import InfoCard from "./InfoCard"
import { mapGetters, mapActions } from "vuex"

export default {
	components:{
		InfoCard
	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin
	],
	watch: {
		'form': {
			handler(){
				if(this.form.theModel == null){
					this.form.theModel = {
						dk_code: null
					}
				}
			},
			deep: true,
		}
	},
	data(){
		return {
			form: {
				theModel: {
					dk_code: null
				}
			},
			option_model_code: [],
			option_make: [],
			option_model: [],
			option_year: [],
			option_region: [],
			option_seater_number: [],
			help_dialog: false,
			option_model_code_by_region: [],
		}
	},
	computed:{
		buttonOff(){
			return (this.form.theModel.dk_code == null || ( this.form.seater_number == null))
			//return !this.form.make || !this.form.model_name || !this.form.year || !this.form.seater_number || !this.form.region
		}
	},
	created(){
		this.scrollToTop()
		this.initialize_()
		//this.autoSelectSeat()
	},
	activated(){
		this.scrollToTop()
	},
	methods:{
		...mapActions(["setSelectedSeat"]),
		initialize_(){
			this.getMakerOption()
			
		},
		searchData_(){
			this.searchModelCodeOption()
		},
		getMakerOption(){
			let payload = {
				itemsPerPage: 100,
				is_existing: 1,
				glb_only: 1,
				orderByName: 1,
			}
			this.$api.getMakerList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_make = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getSDModelOption(){
			this.form.theModel = {
				dk_code: null
			}
			this.option_model_code = []
			this.form.model_name = ''
			this.option_model = []
			this.form.year = ''
			this.option_year = []
			this.form.region = ''
			this.option_region = []
			this.form.seater_number = ''
			this.option_seater_number = []
			this.option_model_code_by_region = []
			
			let payload = {
				maker_id: this.form.make,
				itemsPerPage: 1000,
				glb_only: 1,
			}
			this.$api.getSDModelList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_model = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getSDYearOption(){
			this.form.theModel = {
				dk_code: null
			}
			this.option_model_code = []
			this.form.year = ''
			this.option_year = []
			this.form.region = ''
			this.option_region = []
			this.form.seater_number = ''
			this.option_seater_number = []
			this.option_model_code_by_region = []

			this.form.region = null
			this.form.seater_number = null
			let payload = {
				maker_id: this.form.make,
				model_name: this.form.model_name,
				glb_only: 1,
			}
			this.$api.getSDYearList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_year = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getModelCodeOption(resetRegion = false){
			this.form.theModel = {
				dk_code: null
			}
			this.option_model_code = []
			this.option_model_code_by_region = []

			if(resetRegion){
				this.form.region = null
				this.option_region = []
				this.form.seater_number = ''
				this.option_seater_number = []
			}
			let payload = {
				maker_id: this.form.make,
				model_name: this.form.model_name,
				year : this.form.year,
				glb_only: 1,
				// seater_id : this.form.seater_number,
				// region_id: this.form.region,
				is_archived: 0,
			}

			this.model_loading_ = true
			this.$api.getSDList(payload).then((res)=>{
				let { data, total } = res.data.result
				this.option_model_code = data
				if(total == 1){
					this.selectModel(data[0])
				}else{
					this.form.region = null

					console.log(data.map((item)=> {
						return {
							region: item.region.name,
							seater: item.seater.name
						}
					}))
					this.option_region = this.getDistinctArray(data.map((item)=> item.region) )
					this.option_seater_number = this.getDistinctArray(data.map((item)=> item.seater) )
					if(this.option_region.length == 1){
						this.form.region = this.option_region[0].id
						if(this.option_seater_number.length == 1){
							this.form.seater_number = this.option_seater_number[0].id
						}
					}else{
						this.option_model_code_by_region = data
					}
				}
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		getDistinctArray(collections){
			console.log(collections)
			let distinctIDs = [ ... new Set(collections.map((item)=> item.id)) ]
			let distinctArray = []
			distinctIDs.forEach((id) => {
				distinctArray.push( collections.find((item)=> item.id == id ) )
			})
			return distinctArray
		},
		searchModelCodeOption(){
			console.log("searchModelCodeOption")

			let payload = {
				dk_code : this.search_input_,
				glb_only: 1,
				is_archived : 0,
			}
			this.model_loading_ = true
			this.$api.getSDList(payload).then((res)=>{
				let { data, total } = res.data.result
				this.option_model_code = data
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		getSeaterNumberOption(){
			if(this.option_model_code_by_region.length > 0)
			{
				this.form.theModel = {
					dk_code: null
				}
				let related_region_models = this.option_model_code_by_region.filter((item)=> item.region_id == this.form.region)
				this.option_seater_number = this.getDistinctArray(related_region_models.map((item)=> item.seater) )
				if(this.option_seater_number.length == 1){
					this.form.seater_number = this.option_seater_number[0].id
					this.getModelCodeOption()
				}else{
					this.form.seater_number = null
				}
			}
		},
		selectModel(model = null){
			let selectedModel = model ?? this.form.theModel
			if(model){
				this.form.theModel = model
			}

			if(!selectedModel){
				return null
			}

			this.form.make = selectedModel.maker_id

			this.option_model = [ selectedModel.model_name ]
			this.form.model_name = this.option_model[0]
			
			let selectedYear = { year: selectedModel.year }
			if(!this.option_year.find((item)=> item.year == selectedModel.year )){
				this.option_year.push(selectedYear)
			}
			this.form.year = selectedYear.year

			let selectedRegion = selectedModel.region
			if(!this.option_region.find((item)=> item.id == selectedModel.region_id )){
				this.option_region.push(selectedRegion)
			}
			this.form.region = selectedRegion.id

			let selectedSeaterNumber = selectedModel.seater
			if(!this.option_seater_number.find((item)=> item.id == selectedModel.seater_id )){
				this.option_seater_number.push(selectedSeaterNumber)
			}
			this.form.seater_number = selectedSeaterNumber.id
		},
		startCustomization(){
			this.setSelectedSeat(this.form)
			this.$router.push('/new-order/select-seat')
		},
		clearSelection(){
			this.form = {
				theModel: {
					dk_code: null
				}
			}
			this.option_model = []
			this.option_year = []
			this.option_region = []
			this.option_seater_number = []
		},
		getMakerName(id)
		{
			return this.option_make.find(i=>i.id == id) ? this.option_make.find(i=>i.id == id).name : null
		},
		getSeaterName(id)
		{
			return this.option_seater_number.find(i=>i.id == id) ? this.option_seater_number.find(i=>i.id == id).name : null
		},
		scrollToTop() {
			window.scrollTo(0,0);
		}
	}
}
</script>
<style>
.vehicle-information{
	background: url('/images/vehicle_information.png');
	background-size: cover;
}
</style>
